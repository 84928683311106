import { useEffect, useState } from "react";

export const apiSettings = {
  host: "https://spolkanastart.pl/spolkanastart/api",
};

type FetchData<T> = {
  error: Error | undefined;
  loading: boolean;
  data: T | null;
};

const useFetchData = <T>(url: string): FetchData<T> => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiSettings.host}/${url}`);
        if (!response.ok) {
          throw new Error("Problem with fetching data");
        }
        const { content } = await response.json();
        setData(content);
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [url]);
  return { data, loading, error };
};

export default useFetchData;
