import AppRoutes from "./routes";
import s from "./App.module.scss";
import React, { useEffect, useState } from "react";
import "./i18n";

const App = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const secureEnter = localStorage.getItem("acc") === "sns1";

  useEffect(() => {
    const savedValue = localStorage.getItem("acc") || "";
    setInputValue(savedValue);
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    localStorage.setItem("acc", newValue);
  };

  return (
    <>
      {secureEnter ? (
        <React.StrictMode>
          <AppRoutes />
        </React.StrictMode>
      ) : (
        <input onChange={(event) => handleInputChange(event)} />
      )}
    </>
  );
};

export default App;
