import { useEffect, useState } from "react";
import useFetchData from "../../../utils/api";
import { BlogCount, BlogPost } from "../models/blog.models";
import s from "./list.module.scss";
import BlogListItem from "./item/item.component";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const BlogList = () => {
  const { t } = useTranslation();
  const { data: count } = useFetchData<BlogCount>("pages/count");
  const perPageList = [12, 24, 36];
  const [perPage, setPerPage] = useState<number>(12);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [pagesCount, setPagesCount] = useState<number>(1);

  useEffect(() => {
    setPagesCount(count?.amount ? count.amount / perPage : 1);
    setStartIndex(0);
  }, [perPage, count?.amount]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 0; i <= pagesCount; i++) {
      pageNumbers.push(
        <li
          className={classNames({ [s.on]: startIndex === i * perPage })}
          key={i}
          onClick={() => setStartIndex(i * perPage)}
        >
          {i + 1}
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <>
      <h1 className={"pageTitle"}>{t("Articles")}</h1>
      <div className={"wrapper"}>
        <ul className={s.pages}>
          {perPageList.map((item) => (
            <li
              className={classNames({ [s.on]: perPage === item })}
              onClick={() => setPerPage(item)}
            >
              {item}
            </li>
          ))}
        </ul>
        {count?.amount ? (
          <BlogListItems from={startIndex} ammount={perPage} />
        ) : null}
        <ul className={s.paginationNumbers}>{renderPageNumbers()}</ul>
      </div>
    </>
  );
};

export default BlogList;

type ItemsProps = {
  from: number;
  ammount: number;
};

const BlogListItems = ({ from, ammount }: ItemsProps) => {
  const { data } = useFetchData<BlogPost[]>(
    `pages/getMany?from=${from}&amount=${ammount}&orderBy=date&order=DESC`
  );

  return (
    <div className={s.posts}>
      {data?.map((post) => (
        <BlogListItem key={post.id} post={post} />
      ))}
    </div>
  );
};
