import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import s from "./slogan.module.scss";

const Slogan = () => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={s.textAbout}      >
        <div className={s.sloganWrapper}>
          <div className={s.slogan}
            style={{ backgroundImage: `url(${"assets/img/bg2.jpg"})` }}
          >
            {/* <div className={'wrapper'}> */}
            <div className={s.content}>
              <p className={s.sloganText}>
                <span className={s.special}>{t("MainPage.SloganTitle")}</span>&nbsp;{t("MainPage.SloganSecondTitle")}
              </p>
              <p className={s.description}>{t("MainPage.SloganDescription")}</p>
              <div className={s.links}>
                <Link className={s.btn} to="/oferta">
                  {t("MainPage.SloganLink")}
                </Link>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className={'wrapper'}>
            <div className={s.icons}>
              <img className={s.addonIcon} width={'50%'} src="assets/img/bannerImg.svg" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slogan;
