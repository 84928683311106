import React, { createContext, useState, ReactNode, useContext } from 'react';

interface HeaderContextType {
  openedMenu: boolean;
  setOpenedMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

export const HeaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [openedMenu, setOpenedMenu] = useState<boolean>(false);

  return (
    <HeaderContext.Provider value={{ openedMenu, setOpenedMenu }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error('useHeaderContext must be used within a HeaderProvider');
  }
  return context;
};

export default HeaderContext