import Menu from "./menu/menu.component";
import s from "./header.module.scss";
import { HeaderProvider } from './HeaderContext';
import HeaderOptions from "./Options/headerOptions";

const Header = () => {
  return (
    <HeaderProvider>
      <div className={s.headerPlaceholder}></div>
      <header className={s.headerWrapper}>
        <div className={s.mainItems}>
          <div className={s.headerBar}>
            <div className={s.content}>
              <HeaderOptions />
              <Menu />
            </div>
          </div>
        </div>
      </header>
    </HeaderProvider>
  );
};

export default Header;
