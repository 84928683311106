import { useTranslation } from "react-i18next";
import useFetchData from "../../../utils/api";
import Loading from "../../shared/loading/loading.component";
import ServicesListItem from "../item/item.component";
import { ServiceItem } from "../models/services.model";
import s from "./list.module.scss";
import { Link } from "react-router-dom";

const ServicesList = () => {
  const { t } = useTranslation();

  const { data, loading } = useFetchData<ServiceItem[]>(
    "services/getmany?from=0&amount=30"
  );

  return (
    <>
    <h1 className={"pageTitle"}>{t('Offer')}</h1>
    <div className={'wrapper'}>
      <p className={"pageDescription"}>{t('Services.List.Description')}</p>
      <div className={s.services}>
        {data ? (
          loading ? (
            <Loading />
          ) : (
            <div className={s.list}>
              {data.map((item, index) => (
                <ServicesListItem key={item.id} item={item} index={index} />
              ))}
              <div className={s.item}>
                <div className={s.itemContent}>
                  <p>{t("needAnotherHelp")}</p>
                  <Link className={s.link} to={"/kontakt"}>
                    {t("contactUs")}
                  </Link>
                </div>
                <img
                  className={s.bgIcon}
                  alt={""}
                  src={"assets/servicesIcons/last.svg"}
                />
              </div>
            </div>
          )
        ) : null}
      </div>
    </div>
    </>
  );
};

export default ServicesList;
