import { Link } from 'react-router-dom';
import s from './mainCtaInfo.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
    text?: string;
}

const MainCtaInfo = ({text}: Props) => {
    const { t } = useTranslation();

    return (
        <div className={s.cta}>
            <p>{text ? text : t('needALawyerHelp')}?</p>
            <Link className={'btn'} to={'/kontakt'}>{t('contactUs')}</Link>
      </div>
    )
}

export default MainCtaInfo;