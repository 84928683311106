import ReactHtmlParser from "react-html-parser";
import s from "./aboutUs.module.scss";
import { InfoPage as Page } from "../InfoPage/models/infoPage.model";
import useFetchData from "../../utils/api";
import Loading from "../shared/loading/loading.component";

const AboutUs = () => {
  const { data: person, loading: loadingPerson } = useFetchData<Page>(
    `static/getOne?symbol=o_nas_1`
  );
  const { data: person2, loading: loadingPerson2 } = useFetchData<Page>(
    `static/getOne?symbol=o_nas_1`
  );
  const { data } = useFetchData<Page>(`static/getOne?symbol=o_nas`);

  return (
    <>
      <h1 className={"pageTitle"}>{data?.title}</h1>
      <div className={"wrapper"}>
        <div className={s.aboutUsPage}>
          {person ? (
            loadingPerson ? (
              <Loading />
            ) : (
              <div className={s.aboutUs}>
                <div className={s.contentWrapper}>
                  <div className={s.content}>
                    <p className={s.small}>{person.title}</p>
                    <div className={s.body}>{ReactHtmlParser(person.body)}</div>
                  </div>
                  <img
                    className={s.personImg}
                    alt={person.title}
                    src={"/assets/img/o_nas_1.png"}
                  />
                </div>
              </div>
            )
          ) : null}
          {person2 ? (
            loadingPerson2 ? (
              <Loading />
            ) : (
              <div className={s.aboutUs}>
                <div className={s.contentWrapper}>
                  <img
                    className={s.personImg}
                    alt={person2.title}
                    src={"/assets/img/o_nas_1.png"}
                  />
                  <div className={s.content}>
                    <p className={s.small}>{person2.title}</p>
                    <div className={s.body}>{ReactHtmlParser(person2.body)}</div>
                  </div>
                </div>
              </div>
            )
          ) : null}
          {data ? (
            <div className={s.additionalInfo}>
              {ReactHtmlParser(data.body)}
              <img alt={"KIRP"} src={"/assets/img/kirp.png"} />
            </div>
          ) : null}
        </div>

      </div>
    </>
  );
};

export default AboutUs;
