import ReactHtmlParser from "react-html-parser";
import s from "./contact.module.scss";
import { InfoPage as Page } from "../InfoPage/models/infoPage.model";
import useFetchData from "../../utils/api";
import Loading from "../shared/loading/loading.component";
import MapComponent from "./map/map.component";
import ContactForm from "./form/form.component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Contact = () => {
  const { t } = useTranslation();
  const { data, loading } = useFetchData<Page>(`static/getOne?symbol=kontakt`);

  return (
    <>
      <h1 className={"pageTitle"}>{t('Contact.Title')}</h1>
      <div className={'wrapper'}>
        {data ? (
          loading ? (
            <Loading />
          ) : (
            <div className={s.page}>
              <MapComponent />
              <div className={s.contentWrapper}>
                <ContactForm />
                <div className={s.content}>
                  <div className={s.infoWrapper}>
                    <p className={s.info}>{t("Contact.Info.Title")}</p>
                    <p className={s.info}>{t("Contact.Info.Description")}</p>
                  </div>
                  <div className={s.companInfo}>
                    <div className={s.body}>{ReactHtmlParser(data.body)}</div>
                    <Link to="https://kancelariajk.opole.pl/" className={s.sinagure}>
                      <img
                        alt={t("CompanySubName")}
                        src={"/assets/img/kair.png"}
                      />
                      <p>{t("CompanySubName")}</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : null}
      </div>
    </>
  );
};

export default Contact;
