import { Link } from "react-router-dom";
import { MenuItem as Item } from "../models/menu.model";
import { useHeaderContext } from "../../HeaderContext";
import s from "./menuItem.module.scss";

type Props = {
  item: Item;
};

const MenuItem = ({ item }: Props) => {
  const {setOpenedMenu} = useHeaderContext();

  return (
    <li onClick={() => setOpenedMenu(false)} className={s.item}>
      <Link to={item.url}>{item.name}</Link>
    </li>
  );
};

export default MenuItem;
