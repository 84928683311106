import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { useState } from "react";
import { FaInfo } from "react-icons/fa";
import s from "./form.module.scss";
import { Field, Form, Formik } from "formik";
import { ContactFormValues } from "../models/conactForm.model";

const ContactForm = () => {
  const { t } = useTranslation();
  const [openedTooltip, setOpenedTooltip] = useState<boolean>(false);

  const handleSubmit = (values: ContactFormValues) => {
    console.log("xd", values);
  };

  const initialValues = {
    name: "",
    email: "",
    rodo: false,
    message: "",
    check: false,
  };

  return (
    <div className={s.form}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form className={s.formWrapper}>
            <p className={s.formTitle}>{t('Contact.SendRequest')}</p>
            <label className={"label"}>
              <span>
              {t('Contact.FirstName')} <em className={"labelInfo"}>({t('unnecessary')})</em>
              </span>
              <Field className={"input"} type="text" name="name" />
            </label>
            <label className={"label"}>
              <span>{t('Contact.Email')}</span>
              <Field required className={"input"} type="email" name="email" />
            </label>
            <label className={"label"}>
              <span>{t('Contact.Message')}</span>
              <Field as='textarea' required className={"textarea"} name="message" />
            </label>
            <label className={"labelSmall"}>
              <Field required type={"checkbox"} name={'rodo'} />
              <span className={s.rodo}>
                {t("Contact.Rodo.Title")}
                <FaInfo
                  className={s.tooltipIcon}
                  size={22}
                  onClick={() => setOpenedTooltip(() => !openedTooltip)}
                />
                {openedTooltip ? (
                  <div className={s.tooltip}>
                    <p
                      className={s.close}
                      onClick={() => setOpenedTooltip(false)}
                    >
                      &times;
                    </p>
                    {ReactHtmlParser(t("Contact.Rodo.Description"))}
                  </div>
                ) : null}
              </span>
            </label>
            <button type="submit" className={"btn"}>
              {t('Contact.SendForm')}
            </button>
          </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;
