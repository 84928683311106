import useFetchData from "../../../utils/api";
import { FooterItem } from "./models/footer.model";
import { useEffect, useState } from "react";
import Loading from "../loading/loading.component";
import { Link } from "react-router-dom";
import s from "./footer.module.scss";
import ContactInfo from "./contactInfo/contactInfo.component";
import { FaPlus, FaMinus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const Footer = () => {
  const { t } = useTranslation();
  const currenrYear = new Date().getFullYear();
  const { data, loading } = useFetchData<FooterItem[]>("footer/getAll");
  const [footerContent, setFooterContent] = useState<FooterItem[][]>([]);
  const [openedItem, setOpenedItem] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    setOpenedItem(index !== openedItem ? index : null);
  };

  useEffect(() => {
    if (data) {
      setFooterContent(
        data.reduce(
          (groups: FooterItem[][], item: FooterItem): FooterItem[][] => {
            if (!groups[item.blockNr]) {
              groups[item.blockNr] = [];
            }
            groups[item.blockNr].push(item);
            return groups;
          },
          []
        )
      );
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={s.footer}>
          <div className={"wrapper"}>
            <div className={s.footerGroups}>
              <ContactInfo
                openedItem={openedItem}
                toggleItem={toggleItem}
                index={-1}
              />
              {footerContent.map((group, index) => (
                <div className={classNames(s.groupWrapper, { [s.on]: openedItem === index })}>
                  <p onClick={() => toggleItem(index)} className={s.groupTitle}>
                    {t("Footer.Title." + index)}
                    <span className={s.markExtends}>
                    <FaPlus className={s.opened} size={18} />
                    <FaMinus className={s.closed} size={18} />
                    </span>
                  </p>
                  <ul key={`group${index}`} className={s.group}>
                    {group.map(({ url, name }) => (
                      <li key={name} className={s.item}>
                        <Link to={url}>{name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className={s.copyright}>
            {t("CompanyName")} {currenrYear}
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
