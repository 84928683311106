import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import s from "../header.module.scss";
import { useHeaderContext } from "../HeaderContext";

const HeaderOptions = () => {
  const { openedMenu, setOpenedMenu } = useHeaderContext();

  return (
    <div className={s.logoWrapper}>
      {!openedMenu ? (
        <FaBars onClick={() => setOpenedMenu(true)} className={s.hamburger} />
      ) : (
        <FaTimes onClick={() => setOpenedMenu(false)} className={s.hamburger} />
      )}

      <Link to={"/"} className={s.logo}>
        <img alt="/" src="assets/svg/logo.svg" />
      </Link>
      <span className={s.iconPlaceholder}></span>
    </div>
  );
};

export default HeaderOptions;
