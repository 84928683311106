import ReactHtmlParser from "react-html-parser";
import s from "../footer.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaPlus, FaMinus } from "react-icons/fa";
import classNames from "classnames";

type Props = {
  openedItem: number | null;
  toggleItem: Function;
  index: number;
};

const ContactInfo = ({ openedItem, toggleItem, index }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(s.groupWrapper, { [s.on]: openedItem === index })}
    >
      <p onClick={() => toggleItem(index)} className={s.groupTitle}>
        {t("Contact.Title")}
        <span className={s.markExtends}>
          <FaPlus className={s.opened} size={18} />
          <FaMinus className={s.closed} size={18} />
        </span>
      </p>
      <div className={s.group}>
        {ReactHtmlParser(t("Contact.CompanyData"))}
        <Link to={"https://kancelariajk.opole.pl/"} className={s.subCompany}>
          <img alt={""} src={"/assets/img/kk.png"} />
          <span>{t("CompanySubName")}</span>
        </Link>
      </div>
    </div>
  );
};

export default ContactInfo;
