import { Link } from "react-router-dom";
import { ServiceItem } from "../models/services.model";
import Truncate from 'truncate-html';
import s from "./item.module.scss";

type Props = {
  item: ServiceItem;
  index: number;
};

const ServicesListItem = ({ item, index }: Props) => {

  Truncate.setup({ stripTags: true, length: 100 })

  return (
    <Link to={item.symbol} className={s.item}>
      {item.image && (
        <img alt={item.name} src={`assets/servicesIcons/${index}.svg`} />
      )}
      <div className={s.content}>
        <h2 className={s.title}>{item.name}</h2>
        <p className={s.desc}>{Truncate((item.description))}</p>
        <span className={s.ctaLink}>Dowiedz się więcej →</span>
      </div>
    </Link>
  );
};

export default ServicesListItem;
