import { useTranslation } from "react-i18next";
import s from "./mainPage.module.scss";
import TextAbout from "./textAbout/textAbout.component";
import ReactHtmlParser from "react-html-parser";
import Steps from "./steps/steps.component";
import { Link } from "react-router-dom";
import Slogan from "./slogan/slogan.component";

const MainPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Slogan />
      <div className={"wrapper"}>
        <TextAbout />
        <Steps />
      </div>
    </>
  );
};

export default MainPage;
