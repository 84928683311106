import useFetchData from "../../../../utils/api";
import { MenuItem as Item } from "./models/menu.model";
import MenuItem from "./menuItem/menuItem.component";
import Loading from "../../loading/loading.component";
import { useHeaderContext } from "../HeaderContext";
import classNames from 'classnames';
import s from "./menu.module.scss";

const Menu = () => {
  const { openedMenu } = useHeaderContext();
  const { data, loading } = useFetchData<Item[]>("menu/getAll");

  return (
    <div className={s.menu}>
        <div className={s.navWrapper}>
          <ul className={classNames(s.nav, { [s.on]: openedMenu })}>
            {!loading ? (
              data?.map((item) => <MenuItem key={item.id} item={item} />)
            ) : (
              <Loading />
            )}
          </ul>
        </div>
    </div>
  );
};

export default Menu;
