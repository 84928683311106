import { useParams } from "react-router-dom";
import useFetchData from "../../utils/api";
import ReactHtmlParser from "react-html-parser";
import { InfoPage as Page } from "./models/infoPage.model";
import Loading from "../shared/loading/loading.component";
import s from "./InfoPage.module.scss";
import { getImageUrl } from "../../utils/images";
import { useEffect, useState } from "react";

const InfoPage = () => {
  const [additionalImageExists, setAdditionalImageExists] = useState(false);
  const { niceUrl } = useParams<{ niceUrl: string }>();
  const { data, loading } = useFetchData<Page>(
    `static/getOne?symbol=${niceUrl}`
  );

  useEffect(() => {
    const checkImageExists = async () => {
      const img = new Image();
      img.src = `assets/img/${niceUrl}.svg`;

      const result = new Promise<{ path: string; status: string }>(
        (resolve) => {
          img.onload = () => resolve({ path: img.src, status: "ok" });
          img.onerror = () => resolve({ path: img.src, status: "error" });
        }
      );

      const { status } = await result;
      setAdditionalImageExists(status === "ok");
    };

    checkImageExists();
  }, [niceUrl]);

  return loading ? (
    <Loading />
  ) : data ? (
    <>
      <h1 className={"pageTitle"}>{data.title}</h1>
      <div className={'wrapper'}>
        <div className={s.page}>
          <div className={s.contentWrapper}>
            <div>
              <div className={"pageBody"}>{ReactHtmlParser(data.body)}</div>
            </div>
            {data.image ? (
              <img
                alt={data.title}
                src={getImageUrl(data.symbol, data.image)}
              />
            ) : additionalImageExists ? (
              <img
                className={s.additionalImg}
                alt=""
                src={`assets/img/${niceUrl}.svg`}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  ) : null


};

export default InfoPage;
