import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./components/contact/contact.component";
import ServicesList from "./components/services/list/list.component";
import BlogList from "./components/blog/list/list.component";
import BlogPage from "./components/blog/page/page.component";
import InfoPage from "./components/InfoPage/InfoPage.component";
import MainPage from "./components/mainPage/mainPage.component";
// import Breadcrumbs from "./components/breadcrumbs/breadcrumbs";
import Header from "./components/shared/header/header.component";
import Footer from "./components/shared/footer/footer.component";
import AboutUs from "./components/aboutUs/aboutUs.component";
import ServicesPage from "./components/services/page/page.component";

const AppRoutes = () => (
  <Router>
    <Header />
    {/* <Breadcrumbs /> */}
    <Routes>
      <Route path="/kontakt" element={<Contact />} />
      <Route path="/oferta" element={<ServicesList />} />
      <Route path="/oferta/:niceUrl" element={<ServicesPage />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:niceUrl" element={<BlogPage />} />
      <Route path=":niceUrl" element={<InfoPage />} />
      <Route path="/" element={<MainPage />} />

      {/* for special wish */}
      <Route path="/o_nas" element={<AboutUs />} />
    </Routes>
    <Footer />
  </Router>
);

export default AppRoutes;
