import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import s from "./textAbout.module.scss";

const TextAbout = () => {
  const { t } = useTranslation();
  return (
    <div className={'wrapper'}>
    <h2 className={'specialTitle'}>{ReactHtmlParser(t("MainPage.Company.Title"))}</h2>
    <div className={s.description}>
      {ReactHtmlParser(t("MainPage.Company.Description"))}
    </div>
  </div>
  );
};

export default TextAbout;
