import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import s from "./map.module.scss";
import { useTranslation } from 'react-i18next';


const center: LatLngExpression = [50.672546, 17.924358]; // Typ LatLngExpression
const zoom = 16;

const MapComponent = () => {
  const { t } = useTranslation();

    const customIcon = new L.Icon({
        iconUrl: 'assets/img/kkBlack.png', 
        iconSize: [70, 70], 
        iconAnchor: [25, 40], 
        popupAnchor: [0, -32] 
      });


  return (
    <div className={s.map}>

    <MapContainer center={center} zoom={zoom} style={{ height: '350px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={center} icon={customIcon}>
        <Popup>
          {t('CompanySubName')}
        </Popup>
      </Marker>
    </MapContainer>
    </div>
  );
};

export default MapComponent;