import { getImageUrl } from "../../../utils/images";
import ReactHtmlParser from "react-html-parser";
import { ServiceItem } from "../models/services.model";
import s from "./page.module.scss";
import useFetchData from "../../../utils/api";
import Loading from "../../shared/loading/loading.component";
import { useParams } from "react-router-dom";
import MainCtaInfo from "../../shared/mainCtaInfo/mainCtaInfo.component";

const ServicesPage = () => {
  const { niceUrl } = useParams<{ niceUrl: string }>();
  const { data, loading } = useFetchData<ServiceItem>(
    `services/getOne?symbol=${niceUrl}`
  );

  return data ? (
    <>
      <h2 className={"pageTitle"}>{data.name}</h2>
      <div className={"wrapper"}>
        {data ? (
          loading ? (
            <Loading />
          ) : (
            <div className={s.data}>
              <div className={s.contentWrapper}>
                {data.image ? (
                  <img
                    alt={data.name}
                    src={getImageUrl(data.symbol, data.image)}
                  />
                ) : null}
                <div>
                  <div className={s.body}>
                    {ReactHtmlParser(data.description)}
                  </div>
                </div>
              </div>
              <MainCtaInfo />
            </div>
          )
        ) : undefined}
      </div>
    </>
  ) : null;
};

export default ServicesPage;
