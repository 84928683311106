import { Link } from "react-router-dom";
import { BlogPost } from "../../models/blog.models";
import s from "./item.module.scss";
import { getImageUrl } from "../../../../utils/images";
import { useTranslation } from "react-i18next";

type Props = {
  post: BlogPost;
};

const BlogListItem = ({ post }: Props) => {
  const { t } = useTranslation();

  return (
    <Link to={"/blog/" + post.symbol} className={s.post}>
      {post.image ? (
        <img alt={post.title} src={getImageUrl(post.symbol, post.image)} />
      ) : null}
      <div className={s.content}>
        <h2 className={s.title}>{post.title}</h2>
        <span className={'ctaLink'}>{t('ReadMore')} →</span>
        <p className={s.date}>{post.date}</p>
      </div>
    </Link>
  );
};

export default BlogListItem;
