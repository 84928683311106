import { Link } from "react-router-dom";
import useFetchData from "../../../../utils/api";
import { getImageUrl } from "../../../../utils/images";
import { BlogPost } from "../../models/blog.models";
import s from "./leftPanel.module.scss";
import MainCtaInfo from "../../../shared/mainCtaInfo/mainCtaInfo.component";
import { useTranslation } from "react-i18next";

type Props = {
  currentId?: number;
};

const LeftPanelBlogPage = ({ currentId }: Props) => {
  const { t } = useTranslation();

  const { data } = useFetchData<BlogPost[]>(
    "pages/getMany?from=0&amount=6&orderBy=date&order=DESC"
  );
  return (
    <div className={s.latestPosts}>
      {data ? (
        <>
          <h2 className={s.sectionTitle}>{t('NewestPosts')}</h2>
          <div className={s.items}>
            {data.map((item) =>
              currentId !== item.id ? (
                <Link to={'/blog/'+item.symbol} key={item.id} className={s.item}>
                  <p className={s.title}>{item.title}</p>
                  {item.image && (
                    <img
                      alt={item.title}
                      src={getImageUrl(item.symbol, item.image)}
                    />
                  )}
                </Link>
              ) : null
            )}
          </div>
          <MainCtaInfo />
        </>
      ) : null}
    </div>
  );
};

export default LeftPanelBlogPage;
