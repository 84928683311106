import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import s from "./steps.module.scss";

const Steps = () => {
  const { t } = useTranslation();
  const steps = new Array(4).fill(null);

  return (
    <>
    <h2 className={'specialTitle'}>{ReactHtmlParser(t('MainPage.StepsTitle'))}</h2>
    <div className={s.steps}>
      {steps.map((_, index) => (
        <div className={s.step} key={index}>
          <div className={s.stepDetails} key={index}>
            <div className={s.content}>
              <p className={s.title}>{t(`MainPage.Step${index + 1}.Title`)}</p>
              <p className={s.description}>
                {t(`MainPage.Step${index + 1}.Description`)}
              </p>
            </div>
            <img
              alt={t(`MainPage.Step${index + 1}.Title`)}
              src={`/assets/stepsIcons/${index + 1}.svg`}
            />
          </div>
          <span className={s.spacer}></span>
        </div>
      ))}
    </div>
    </>
  );
};

export default Steps;
