import { useLocation, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import useFetchData from "../../../utils/api";
import { BlogPost } from "../models/blog.models";
import { getImageUrl } from "../../../utils/images";
import Loading from "../../shared/loading/loading.component";
import LeftPanelBlogPage from "./leftPanel/leftPanel.component";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import s from "./page.module.scss";

const BlogPage = () => {
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
  const { niceUrl } = useParams<{ niceUrl: string }>();
  const { data, loading } = useFetchData<BlogPost>(
    `pages/getOne?symbol=${niceUrl}`
  );

  return (
    <div className={'wrapper'}>
      <div className={s.postWrapper}>
        <div>
          {loading ? (
            <Loading />
          ) : data && data?.isActive ? (
            <div className={s.post}>
              <p className={s.date}>{data.date}</p>
              <div className={s.titleWrapper}>
                {data.image ? (
                  <img
                    alt={data.title}
                    src={getImageUrl(data.symbol, data.image)}
                  />
                ) : null}
                <h1 className={s.title}>{data.title}</h1>
              </div>
              <div className={s.body}>{ReactHtmlParser(data.body)}</div>
            </div>
          ) : (
            <p>Strona niedostępna.</p>
          )}
          {data ? (
            <>
              <p className={s.shareTitle}>Udostępnij artykuł</p>
              <div className={s.share}>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                  target="_blank"
                >
                  <FaFacebook size={22} />
                </a>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`}
                  target="_blank"
                >
                  <FaLinkedin size={22} />
                </a>
              </div>
            </>
          ) : null}
        </div>
        <LeftPanelBlogPage currentId={data?.id} />
      </div>
    </div>
  );
};

export default BlogPage;
