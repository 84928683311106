import s from './loading.module.scss';

const Loading = () => {
    return (
        <div className={s.loadingContainer}>
        <div className={s.spinner}>
          <div className={s.dot}></div>
          <div className={s.dot}></div>
          <div className={s.dot}></div>
        </div>
      </div>
    )
}

export default Loading;